import IconButton from 'components/IconButton'
import { ArrowLeftIcon } from 'components/icons'
import LogoLink from 'components/logoLink'
import WishlistModalTrigger from 'components/modals/triggers/WishlistModalTrigger'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import { ReactChild } from 'data/types/types'
import { useRouter } from 'next/router'
import React from 'react'
import UserProfileTrigger from './triggers/UserProfileTrigger'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import classNames from 'classnames'
import ShareModalTrigger, { ShareModalTriggerProps } from 'components/modals/triggers/ShareModalTrigger'

export interface FixedNavbarProps {
  children?: ReactChild
  hideChildrenOnMobile?: boolean
  wishlist?: {
    enabled: boolean
  }
  share?: {
    enabled: boolean
  }
  className?: {
    container?: string
    children?: string
  }
  scrollDelay?: number
  scrollDelayOverride?: boolean
  model?: ShareModalTriggerProps['model']
}

const FixedNavbar = ({
  children,
  hideChildrenOnMobile,
  wishlist,
  share,
  model,
  className,
  scrollDelay = 0,
  scrollDelayOverride = true,
}: FixedNavbarProps) => {
  const router = useRouter()
  const { isMobileApp } = useMobileApp()

  const hasScrolled = useHasScrolled(scrollDelay)

  return (
    <nav
      className={classNames(
        'fixed top-0 z-50 pt-safe-offset-10 flex flex-col items-center w-full justify-between lg:h-[80px] bg-white stroke-2',
        'lg:pt-safe-offset-15 gap-y-10 lg:pb-10 lg:flex-row lg:shadow-public-searchbar lg:px-10 xl:px-25',
        {
          'pb-10 lg:pb-0': hideChildrenOnMobile,
          'hidden': scrollDelayOverride && !hasScrolled,
        },
        className?.container,
      )}
      aria-hidden={!hasScrolled}
    >
      <div className="flex items-center justify-between w-full gap-16 px-20 lg:hidden">
        <IconButton
          variant="navbar"
          onClick={() => router.back()}
        >
          <ArrowLeftIcon className="stroke-2 text-24" />
        </IconButton>

        <div className="flex gap-5 text-grey-800">
          {wishlist?.enabled && model?.__typename === 'Property' && (
            <WishlistModalTrigger
              propertyId={model.id}
              buttonVariant="propertyMobileHeart"
            />
          )}

          {share?.enabled && model?.__typename === 'Property' && (
            <ShareModalTrigger
              model={model}
              iconSize={22}
            />
          )}

          {!isMobileApp && (
            <UserProfileTrigger />
          )}
        </div>
      </div>

      <div className="hidden lg:block basis-1/5">
        <LogoLink
          className="max-w-[101px]"
          variant="no-tag-line"
        />
      </div>

      {children && (
        <div className={classNames(
          'relative flex w-full h-full max-w-4xl min-w-0 mx-auto',
          { 'hidden lg:flex': hideChildrenOnMobile },
          className?.children,
        )}>
          {children}
        </div>
      )}

      <div className="justify-end hidden gap-x-3 lg:flex basis-1/5 text-grey-700">
        {share?.enabled && model?.__typename === 'Property' && (
          <ShareModalTrigger
            model={model}
            iconSize={22}
          />
        )}

        {wishlist?.enabled && model?.__typename === 'Property' && (
          <WishlistModalTrigger
            propertyId={model.id}
            buttonVariant="propertyMobileHeart"
          />
        )}

        {share?.enabled && model?.__typename === 'Wishlist' && (
          <ShareModalTrigger
            model={model}
            iconSize={22}
          />
        )}

        <UserProfileTrigger />
      </div>
    </nav>
  )
}

export default FixedNavbar
