import { ReactChild } from 'data/types/types'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import classNames from 'classnames'


export interface NavbarButtonProps {
  prefix?: ReactChild
  children: ReactChild
  suffix?: ReactChild
  active?: boolean
  className?: {
    container?: string
    text?: string
  }
}

const NavbarButton = ({
  prefix,
  children,
  suffix,
  active,
  className,
}: NavbarButtonProps) => {
  return (
    <div className={classNames('flex items-center h-full gap-15', className?.container)}>
      {prefix && (
        <div className="text-primary-fresh text-20 pl-30">
          {prefix}
        </div>
      )}

      <Paragraphs.XL
        className={classNames(
          {
            'text-grey-700': !active,
            'text-grey-800': active,
            'flex-grow': suffix && prefix,
          },
          className?.text,
        )}
      >
        {children}
      </Paragraphs.XL>

      {suffix && (
        <div className="text-primary-fresh text-20 pr-30">
          {suffix}
        </div>
      )}
    </div>
  )
}

export default NavbarButton
